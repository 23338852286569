import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CatHeading } from "blocks-react/bedrock/components/Heading/Heading";
import { CatLoadingIndicator } from "blocks-react/bedrock/components/LoadingIndicator/LoadingIndicator";
import { useEffect } from "react";
import TimelineVerticalLine from "../../assets/TimelineVerticalLine.svg";
import { getAssetImageIcon, trimAssetName } from "../../pages/Projects/ProjectDetails.helper";
import { isEmpty } from "../../shared/helper";
import useGlobalStore from "../../store/useGlobalStore";
import Timeline from "./Timeline";
const ActivityTimeline = ({ assetChargeActivityData, assetsDetails }) => {
    const isLoading = useGlobalStore((state) => state.isLoading);
    useEffect(() => {
        //Wrapping this in setTimeout without any delay helps us finding the shadow-root element
        setTimeout(() => {
            //Finding the shadow-root element to modify the styles of the table row asset-portfolio-table-row
            const allRows = document.querySelectorAll(".activity-timeline-row");
            //Commenting this allHeaderCell to use later
            // const allHeaderCell = document.querySelectorAll(".timeline-header-cell");
            allRows.forEach((rowEle) => {
                const tableRowElement = rowEle?.shadowRoot.querySelector(".cat-c-table-row");
                tableRowElement?.setAttribute("style", "border-bottom: 1px solid #CCC");
            });
        });
    });
    const populateTimeLineDetails = (assetsDetails) => {
        return assetsDetails?.map((assetInfo, index) => {
            const bobMessage = assetInfo?.assetMetadata?.make === "BAB" || assetInfo?.assetMetadata?.make === "PSW"
                ? assetInfo?.assetMetadata?.productFamily?.name
                : assetInfo?.assetMetadata?.make;
            const assetChargeActivity = assetChargeActivityData.filter((activityData) => activityData.assetId === assetInfo.assetId);
            assetChargeActivity.sort((a, b) => {
                const dateA = new Date(a.activityStartTimeStamp).getTime();
                const dateB = new Date(b.activityStartTimeStamp).getTime();
                return dateA - dateB;
            });
            const { chargeEnergySum = 0, chargeEnergySumTime = "", dischargeEnergySum = 0, dischargeEnergySumTime = "" } = getChargeDetails(assetChargeActivity);
            const chartId = `timeline-chart-id-${index}`;
            return (_jsxs("tr", { className: "border-b", children: [_jsx("td", { children: _jsxs("div", { className: "inline-flex", children: [_jsx("div", { children: _jsx("img", { alt: "Assets", className: "w-16 p-3", src: getAssetImageIcon(assetInfo) }) }), _jsxs("div", { className: "cursor-pointer", children: [_jsx(CatHeading, { "data-test": `${assetInfo?.assetSerialNumber}-assetname`, variant: "display-sm", children: trimAssetName(assetInfo?.assetName) }), _jsxs("span", { "data-test": `${assetInfo?.assetSerialNumber}-assetDetails`, children: [assetInfo?.assetSerialNumber && assetInfo?.assetMetadata?.make
                                                    ? `${assetInfo?.assetSerialNumber} - ${bobMessage}`
                                                    : `${assetInfo?.assetSerialNumber || ""}${bobMessage || ""}`, " ", assetInfo?.assetMetadata?.model] })] })] }) }), _jsx("td", { colSpan: 6, children: _jsx(Timeline, { chartData: assetChargeActivity, chartId: chartId }) }), _jsxs("td", { className: "pl-4 pr-2 text-sm", children: [_jsxs("div", { className: "text-[#1AC69E]", children: [Math.round(chargeEnergySum), " kWh / ", chargeEnergySumTime, " Hr"] }), _jsxs("div", { className: "text-[#FB5A00]", children: [Math.round(dischargeEnergySum), " kWh / ", dischargeEnergySumTime, " Hr"] })] })] }, index));
        });
    };
    const padZero = (value) => {
        if (value < 10) {
            return "0" + value;
        }
        else {
            return value;
        }
    };
    const getChargeDetails = (chargeActivity) => {
        let chargeEnergySum = 0, chargeEnergySumTime = "0:0", chargeEnergyTimeDiffInSec = 0, dischargeEnergySum = 0, dischargeEnergySumTime = "0:0", dischargeEnergyTimeDiffInSec = 0;
        if (chargeActivity.length > 0) {
            chargeActivity.map((item) => {
                const { activity = "", activityEndTimeStamp = "", activityStartTimeStamp = "", chargeEnergy = 0, dischargeEnergy = 0 } = item;
                const activityStartDate = new Date(activityStartTimeStamp);
                const activityEndDate = new Date(activityEndTimeStamp);
                chargeEnergySum += chargeEnergy;
                if (activity !== "Trickle") {
                    dischargeEnergySum += dischargeEnergy;
                }
                if (activity === "Charging" || activity === "AC_Charging" || activity === "DC_Charging") {
                    chargeEnergyTimeDiffInSec += (activityEndDate.getTime() - activityStartDate.getTime()) / 1000;
                }
                else {
                    dischargeEnergyTimeDiffInSec += (activityEndDate.getTime() - activityStartDate.getTime()) / 1000;
                }
            });
        }
        // Calculate charge time in hours and minutes
        if (chargeEnergyTimeDiffInSec > 0) {
            const milliSeconds = 3600;
            const hours = Math.floor(chargeEnergyTimeDiffInSec / milliSeconds);
            const minutes = Math.floor((chargeEnergyTimeDiffInSec % milliSeconds) / 60);
            chargeEnergySumTime = `${padZero(hours)}:${padZero(minutes)}`;
        }
        // Calculate discharge time in hours and minutes
        if (dischargeEnergyTimeDiffInSec > 0) {
            const milliSeconds = 3600;
            const hours = Math.floor(dischargeEnergyTimeDiffInSec / milliSeconds);
            const minutes = Math.floor((dischargeEnergyTimeDiffInSec % milliSeconds) / 60);
            dischargeEnergySumTime = `${padZero(hours)}:${padZero(minutes)}`;
        }
        return {
            chargeEnergySum,
            chargeEnergySumTime,
            dischargeEnergySum,
            dischargeEnergySumTime
        };
    };
    return (_jsx("div", { className: "relative", children: _jsx(_Fragment, { children: isLoading ? (_jsx("div", { className: "flex justify-center items-center h-full min-h-[28vh]", children: _jsx(CatLoadingIndicator, {}) })) : (_jsx(_Fragment, { children: !isEmpty(assetsDetails) && (_jsx("div", { className: "overflow-y-auto h-[470px]", "data-test": "timeline-data", id: "assets-portfoilo-table", children: _jsxs("table", { className: "table-auto w-full text-left bg-white rounded-lg", "data-test": "assets-portfoilo-table", children: [_jsx("thead", { className: "sticky top-0 border-b py-4 z-10 bg-white", children: _jsxs("tr", { children: [_jsx("th", { className: "px-4 text-sm", children: "Assets" }), _jsx("th", { className: "w-[10%] text-sm", "data-test": "Time-00:00", children: "00:00" }), _jsx("th", { className: "w-[10%] text-sm", "data-test": "Time-04:00", children: "04:00" }), _jsx("th", { className: "w-[10%] text-sm", "data-test": "Time-08:00", children: "08:00" }), _jsx("th", { className: "w-[10%] text-sm", "data-test": "Time-12:00", children: "12:00" }), _jsx("th", { className: "w-[10%] text-sm", "data-test": "Time-16:00", children: "16:00" }), _jsxs("th", { className: "w-[12%] text-sm", children: [_jsx("span", { className: "float-left", "data-test": "Time-20:00", children: "20:00" }), _jsx("span", { className: "float-right", children: "00:00" })] }), _jsxs("th", { className: "w-[15%] text-sm pl-4 pr-2", "data-test": "Total-Value-Header", children: ["Total Charge ", _jsx("br", {}), "Total Discharge"] })] }) }), _jsxs("tbody", { "data-test": "Timeline-Body", children: [populateTimeLineDetails(assetsDetails), _jsx("img", { className: "absolute h-[91.5%] bottom-0 z-10 hidden", "data-test": "Timeline-Vertical-Line-Image", id: "timeline-chart-line", src: TimelineVerticalLine })] })] }) })) })) }) }));
};
export default ActivityTimeline;
